<div class="loading-container">
  <table mat-table [dataSource]="tableDataKpi" class="mat-elevation-z8">
    <ng-container matColumnDef="run">
      <th mat-header-cell *matHeaderCellDef class="col-align-center">Run</th>
      <td mat-cell *matCellDef="let element" class="col-align-center">
        {{ element.run }}
      </td>
    </ng-container>

    <ng-container matColumnDef="starttime">
      <th mat-header-cell *matHeaderCellDef class="col-align-left">Start Time (UTC)</th>
      <td mat-cell *matCellDef="let element" class="col-align-left">
        {{ element.starttime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endtime">
      <th mat-header-cell *matHeaderCellDef class="col-align-left">End Time (UTC)</th>
      <td mat-cell *matCellDef="let element" class="col-align-left">
        {{ element.endtime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="KPI_HeatEffectMainHeaterPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["KPI_HeatEffectMainHeaterPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.KPI_HeatEffectMainHeaterPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="KPI_CoolingIceWaterPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["KPI_CoolingIceWaterPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.KPI_CoolingIceWaterPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="KPI_CompElecInputPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["KPI_CompElecInputPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.KPI_CompElecInputPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="COP">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["COP"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.COP }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="KpiDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: KpiDisplayedColumns"></tr>
  </table>
</div>

<div class="loading-container">
  <table mat-table [dataSource]="tableDataParams" class="mat-elevation-z8">
    <ng-container matColumnDef="run">
      <th mat-header-cell *matHeaderCellDef class="col-align-left">Run</th>
      <td mat-cell *matCellDef="let element" class="col-align-left">
        {{ element.run }}
      </td>
    </ng-container>

    <ng-container matColumnDef="starttime">
      <th mat-header-cell *matHeaderCellDef class="col-align-left">Start Time (UTC)</th>
      <td mat-cell *matCellDef="let element" class="col-align-left">
        {{ element.starttime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endtime">
      <th mat-header-cell *matHeaderCellDef class="col-align-left">End Time (UTC)</th>
      <td mat-cell *matCellDef="let element" class="col-align-left">
        {{ element.endtime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="MainAirTempBeforePreheaterPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["MainAirTempBeforePreheaterPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.MainAirTempBeforePreheaterPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="MainAirFlowRatePV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["MainAirFlowRatePV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.MainAirFlowRatePV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="CowWaterFlowPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["CowWaterFlowPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.CowWaterFlowPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="CowWaterTempPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["CowWaterTempPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.CowWaterTempPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ColdWaterInletTempPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["ColdWaterInletTempPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.ColdWaterInletTempPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="CondFlowEcoPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["CondFlowEcoPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.CondFlowEcoPV }}
      </td>
    </ng-container>

    <ng-container matColumnDef="CondTempBeforeEcoPV">
      <th mat-header-cell *matHeaderCellDef="displayTags" class="col-align-right">
        {{ displayTags["CondTempBeforeEcoPV"] }}
      </th>
      <td mat-cell *matCellDef="let element" class="col-align-right">
        {{ element.CondTempBeforeEcoPV }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="ParamsDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: ParamsDisplayedColumns"></tr>
  </table>
</div>
